import React, { useRef, useEffect, useState } from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"

import Logo from '../assets/logo.png'

const Cara = () => {
  const layoutRef = useRef(null)
  const [big, setBig] = useState(false)

  useEffect(() => {
    setBig(layoutRef.current?.getBoundingClientRect().width < 500)
  }, [])

  return (
    <div ref={layoutRef}>
  <Layout>
    <div style={{color: 'white', height: 60, display: 'flex', padding: 9}}>
      <div style={{flex: 1}}><img style={{maxHeight: '100%', width: 33}} src={Logo}/></div>
      <div style={{flex: 1, textAlign: 'right', lineHeight: '42px'}}><a style={{color: '#ef6361'}} href="https://app.deltareaders.com">LOGIN</a></div>
    </div>
    <Parallax pages={big ? 7 : 5}>
      <Hero offset={0} factor={1} />
      <Projects offset={1} factor={big ? 4 : 2} />
      <About offset={big ? 5 : 3} factor={1} />
      <Contact offset={big ? 6 : 4} factor={1} />
    </Parallax>
  </Layout>
  </div>
)
  }

export default Cara
