/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui"

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <Box as="footer" variant="footer">
      Reach us at support@deltareaders.com
      <br/>
      <br/>
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
      <br />
    </Box>
  )
}

export default Footer
