import React from 'react'

const Contact = () => (
    <>
        Pricing Information. 
        Limited time: First Book Free with Unlimited Readers. 
        Future Prices: $10/month unlimited books with 5 readers. 
        OR: $19/month unlimited books unlimited readers, full power of the Delta Readers Platform
        OR: $50 a book
        (this is a business expense, write it off)
    </>
)
export default Contact