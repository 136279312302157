/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { hidden } from "../styles/utils"

import BookIcon from '../assets/book.inline.svg'
import BookMark from '../assets/bookmark.inline.svg'
import Tilted from '../assets/titledbook.inline.svg'
import Spine from '../assets/spine.inline.svg'
import Open from '../assets/openbook.inline.svg'
import Two from '../assets/twobooks.inline.svg'
import Three from '../assets/three.inline.svg'

const colors = [
  '#ef6361', '#d15958', '#cef2ff', '#ecf2ff', '#a9d4eb', '#64add6', '#3c4b73', '#283358'
]

const icons = {
  triangle: {
    shape: <BookIcon/>,
    viewBox: `0 0 30 30`,
  },
  circle: {
    shape: <BookMark/>,
    viewBox: `0 0 30 30`,
  },
  arrowUp: {
    shape: <Tilted/>,
    viewBox: `0 0 30 42`,
  },
  upDown: {
    shape: <Spine/>,
    viewBox: `0 0 30 44.58`,
  },
  box: {
    shape: <Open/>,
    viewBox: `0 0 30 30`,
  },
  hexa: {
    shape: <Two/>,
    viewBox: `0 0 30 30`,
  },
  cross: {
    shape:<Three/>,
    viewBox: `0 0 100 100`,
  },
}

type IconType = "triangle" | "circle" | "arrowUp" | "upDown" | "box" | "hexa" | "cross"

type SVGProps = {
  stroke?: boolean
  color?: string | number | any
  width: number
  icon: IconType
  left: string
  top: string
  hiddenMobile?: boolean
}

const SVG = ({ stroke = false, color = ``, width, icon, left, top, hiddenMobile = false }: SVGProps) => (
  <div
  style={{
        position: `absolute`,
        stroke: colors[color],
        fill: colors[color],
        display: hiddenMobile ? hidden : `block`,
        color,
        width,
        left,
        top,
      }}
      viewBox={icons[icon].viewBox}>
  {icons[icon].shape}
  </div>
)

export default SVG
