import React from 'react'
import ProjectCard from '../components/project-card'

const Projects = () => (
  <>

<ProjectCard
  title="Centralized Feedback"
  bg="linear-gradient(to right, #ef6361 0%, #a9d4eb 100%)"
>
  This project is my entry to Adobe's #ChallengeYourPerspective contest.
</ProjectCard>
<ProjectCard
  title="Reader Analytics"
  bg="linear-gradient(to right, #64add6 0%, #a9d4eb 100%)"
>
  I entered the DOCMA 2017 award with this Harry Potter inspired image.
</ProjectCard>
<ProjectCard
  title="Secure"
  bg="linear-gradient(to right, #283358 0%, #d15958 100%)"
>
  Recreation of a Tomb Raider Wallpaper (Fan Art)
</ProjectCard>
<ProjectCard
  title="Invite Your Readers"
  bg="linear-gradient(to right, #64add6 0%, #ef6361 100%)"
>
  A fantasy image manipulation relocating the habitat of wild animals.
</ProjectCard>
<ProjectCard
  title="Upload DocX"
  bg="linear-gradient(to right, #283358 0%, #3c4b73 100%)"
>
  A fantasy image manipulation relocating the habitat of wild animals.
</ProjectCard>
<ProjectCard
  title="Artifical Intelligence"
  bg="linear-gradient(to right, #d15958 0%, #3c4b73 100%)"
>
  A fantasy image manipulation relocating the habitat of wild animals.
</ProjectCard>
<ProjectCard
  title="Unparralled Insights"
  bg="linear-gradient(to right, #283358 0%, #ecf2ff 100%)"
>
  A fantasy image manipulation relocating the habitat of wild animals.
</ProjectCard>
<ProjectCard
  title="Power of the big five in your hands"
  bg="linear-gradient(to right, #64add6 0%, #ef6361 100%)"
>
  A fantasy image manipulation relocating the habitat of wild animals.
</ProjectCard>

</>)

export default Projects

// const colors = [
//   '#ef6361', '#d15958', '#a9d4eb', '#64add6', '#3c4b73', '#283358'
// ]