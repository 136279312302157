import React from 'react'

const Intro = () => (
<>
<h1 style={{fontSize: '4.5rem', marginTop: 9, marginBottom: 18}}>Delta Readers</h1>

<div style={{fontSize: '1.875rem'}}>
Next generation narrative tools for the every day author. Turn any reader into a super powered resource to help you write novels readers can't put down.
</div>
</>
)
export default Intro

// Next generation narrative tools for the every day author. Turn any reader into a super powered resource to help you write novels people can't put down.
